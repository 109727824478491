<uc-subsection>
  <uc-task-hint
    [task]="task"
    path="/applicant/demographic/ethnicity/0"
  ></uc-task-hint>
  <uc-subsection-question
    >{{ strings.ethnicityQuestion }}<uc-required-field></uc-required-field
  ></uc-subsection-question>
  <uc-subsection-minor-text><span [innerHTML]="strings.ethnicitySubtext"></span></uc-subsection-minor-text>

  <div [formGroup]="demographicForm">
    <uc-form-input formArrayName="ethnicities">
      <div
        class="ethnicity-selectors"
        *ngFor="let ethnicity of ethnicityControls.controls; let last = last; let first = first; let i = index"
        [formGroupName]="i"
      >
        <div class="item-label">
          <div class="item-tab">{{ strings.ethnicityNumber[i] }}</div>
        </div>

        <div class="item-content-wrapper">
          <div class="item-content-section">
            <uc-reference-data-selector
              class="item-selector"
              [labelName]="strings.ethnicityLabel"
              [formControl]="ethnicity"
              [filter]="ethnicityFilter"
              type="ethnicity"
            ></uc-reference-data-selector>
          </div>

          <div
            class="item-content-section"
            *ngIf="ethnicity.value?.code === '2'"
            [formGroup]="demographicForm"
          >
            <uc-task-hint
              [task]="task"
              [path]="'/applicant/demographic/iwi/' + i"
            ></uc-task-hint>
            <uc-subsection-question
              >{{ strings.iwiQuestion }}<uc-required-field></uc-required-field
            ></uc-subsection-question>
            <uc-subsection-minor-text>{{ strings.iwiSubtext }}</uc-subsection-minor-text>
            <uc-form-input formArrayName="iwi">
              <div
                class="iwi-selectors"
                *ngFor="let iwi of iwiControls.controls; let last = last; let first = first; let i = index"
                [formGroupName]="i"
              >
                <div>
                  <uc-reference-data-selector
                    class="item-selector"
                    [labelName]="strings.iwiLabel"
                    [filter]="iwiFilter"
                    [formControl]="iwi"
                    [useNgSelect]="true"
                    type="iwi"
                  >
                  </uc-reference-data-selector>
                  <uc-icon
                    class="remove-item-action"
                    *ngIf="iwiControls.length > 1"
                    iconType="trash"
                    iconHeight="16"
                    iconWidth="16"
                    (click)="removeIwi(i)"
                  >
                  </uc-icon>
                </div>
                <span
                  class="add-selector"
                  *ngIf="last && iwiControls.length < 3"
                  (click)="addIwi(i)"
                >
                  <uc-icon
                    iconType="add"
                    iconHeight="23"
                    iconWidth="23"
                    iconFill="$colorAbbey"
                  ></uc-icon>
                  {{ strings.addIwi }}
                </span>
              </div>
            </uc-form-input>
          </div>

          <div
            *ngIf="ethnicityControls.length > 1"
            class="delete-btn"
            (click)="removeEthnicity(i)"
          >
            <uc-icon
              class="delete-btn-icon"
              iconType="trash"
              iconWidth="16"
              iconHeight="16"
              iconFill="$colorFireEngineRed"
            ></uc-icon>
            <span class="delete-btn-text">{{ strings.removeEthnicityText }}</span>
          </div>
        </div>
        <uc-add-new-button
          *ngIf="last && ethnicityControls.length < 3"
          buttonHeading="{{ strings.addEthnicity }}"
          buttonText="{{ strings.addThreeEths }}"
          (click)="addEthnicity()"
        >
        </uc-add-new-button>
      </div>
    </uc-form-input>
  </div>
</uc-subsection>
