import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { flatten, get } from 'lodash-es';
import { combineLatest, Observable, Subscription, throwError, zip } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { ConfirmationModalComponent } from '@shared/components/atoms/confirmation-modal/confirmation-modal.component';
import { CourseSearchComponent } from '@shared/components/molecules/course-search/course-search.component';
import { SearchFiltersComponent } from '@shared/components/organisms/search-filters/search-filters.component';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { CHANGE_ACTIONS } from '@shared/constants/states.constants';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ChangeOfEnrolmentCourse, ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { CourseOccurrence, EnrolledCourse } from '@shared/models/course';
import { UCError } from '@shared/models/errors';
import { EnrolledQualification, Qualification } from '@shared/models/qualification';
import { QualificationResult } from '@shared/models/qualification-result';
import { TeachingPeriod } from '@shared/models/teaching-period';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { CourseService, DisplayType } from '@shared/services/course/course.service';
import { FullEnrolmentService } from '@shared/services/full-enrolment/full-enrolment.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-course-planner',
  templateUrl: './course-planner.component.html',
  styleUrls: ['./course-planner.component.scss'],
})
export class CoursePlannerComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
  @ViewChild(SearchFiltersComponent) searchFilter;
  @ViewChild(CourseSearchComponent) searchBar;
  @ViewChild(ConfirmationModalComponent) errorModal: ConfirmationModalComponent;

  private subscriptions: Subscription[];
  public log: Logger;
  public courses: CourseOccurrence[];
  public teachingPeriods: TeachingPeriod[];
  public qualification: Qualification;
  public enrolmentInternalReference: string;
  public enrolledQualifications: EnrolledQualification[];
  public enrolledQualification: EnrolledQualification;
  public otherQual: EnrolledQualification;
  public processName: string;
  public routeParams = { applicationYear: '', enrolmentPriority: '', qualPriority: '' };
  public activeCourses = [];
  public filterOrder: Record<string, { labelText: string; value: string }[]> = {};

  strings = strings.components.organisms.coursePlanner;
  loading = true;
  noCoursesFound = false;
  tooManyResults = false;
  showBlips: boolean;
  searchResultCount: number;
  contentSearchOptions: {
    formControlName: string;
    options: { code: { code: string }; name: string }[];
    disabled?: boolean;
  }[];
  showFilters = false;
  filterText = this.strings.filterResults;
  searchDisplayType;
  displayType = DisplayType;
  mobileBreakpointWidth = 768;
  errorTriggered = false;
  enrolmentChange: ChangeOfEnrolment;

  defaultCurriculumGroup = '';
  defaultSubjectGroup = '';
  courseGroupGuidanceText = '';
  showGuidance = true;
  selectedCourses: Record<string, CourseOccurrence>;

  isMobile = window.innerWidth < this.mobileBreakpointWidth;

  constructor(
    loggingService: LoggingService,
    public courseService: CourseService,
    private fullEnrolmentService: FullEnrolmentService,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private router: Router,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get hasEnrolmentChange(): boolean {
    return this.enrolmentChange && this.enrolmentChange?.academicYear?.code === this.routeParams.applicationYear;
  }

  get otherQualUrl() {
    if (this.otherQual) {
      const otherQualPriority = this.routeParams.qualPriority === '1' ? 2 : 1;
      const { applicationYear, enrolmentPriority } = this.routeParams;
      return internalUrls.courseSelector(applicationYear, enrolmentPriority, otherQualPriority).join('/');
    }
    return false;
  }

  @HostListener('window:resize') onResize() {
    this.isMobile = window.innerWidth < this.mobileBreakpointWidth;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => sub && sub.unsubscribe());
    }
  }

  ngOnInit() {
    this.subscriptions = [];

    this.route.params.subscribe((params) => {
      if (!params.applicationYear || !params.enrolmentPriority || !params.qualPriority) {
        return this.router.navigate(['/']);
      }
      if (this.routeParams.enrolmentPriority !== params.enrolmentPriority) {
        this.courseService.clearCache();
      }
      Object.assign(this.routeParams, params);
    });

    combineLatest([
      this.applicationService.getApplication(this.routeParams.applicationYear),
      this.changeOfEnrolmentService.getChangeOfEnrolment(),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([application, coe]) => {
        this.enrolmentChange = coe;
        this.loading = false;
        if (this.hasEnrolmentChange) {
          this.processName = PROCESS_NAMES.COE;
        } else if (get(application, 'processName.code')) {
          this.processName = application.processName.code;
        } else {
          this.router.navigate(internalUrls.dashboard);
        }
        this.fetchViewDataSubscription();
      });

    const courseSearchUpdateSubscription = this.courseService.courseResults.subscribe((searchCourses) => {
      this.searchResultCount = searchCourses && searchCourses.length;
      if (searchCourses && searchCourses.length) {
        this.courses = searchCourses;
      } else {
        this.courses = undefined;
      }
    });

    const courseServiceSelectionSubscription = this.courseService.selectedCourses.subscribe((courses) => {
      let selectedCoursesMap = {};
      if (courses) {
        selectedCoursesMap = courses.reduce((prev, curr) => {
          prev[curr.courseOccurrenceCode] = {
            selected: true,
            changeAction: curr.changeAction,
          };
          return prev;
        }, {});
      }
      this.selectedCourses = selectedCoursesMap;
    });

    const searchServiceSearchResultsSubscription = this.courseService.searchResult.subscribe((searchResults) => {
      if (searchResults) {
        this.noCoursesFound = !searchResults.course.length && searchResults.meta.resultCount === 0;
        this.tooManyResults = !searchResults.course.length && searchResults.meta.resultCount > 0;
        this.showBlips = false;
        this.courseService.searchResultError.subscribe(() => {
          this.noCoursesFound = true;
        });
      }
    });

    this.subscriptions.push(
      courseSearchUpdateSubscription,
      courseServiceSelectionSubscription,
      searchServiceSearchResultsSubscription,
    );

    this.searchDisplayType = this.courseService.searchDisplayType$.asObservable();
  }

  fetchEnrolledQuals(): Observable<[QualificationResult, CourseOccurrence[], TeachingPeriod[]]> {
    if (this.processName === PROCESS_NAMES.COE && this.enrolmentChange) {
      return this.changeOfEnrolmentService.getChangeOfEnrolment().pipe(
        switchMap((changeOfEnrolment) => {
          this.processName = PROCESS_NAMES.COE;

          if (!changeOfEnrolment) {
            return throwError('No change of enrolment found.');
          }
          const enrolledQuals = changeOfEnrolment.enrolledQualifications;
          this.enrolledQualifications = enrolledQuals;

          return this.fullEnrolmentService.getEnrolledQualData(this.routeParams.applicationYear, enrolledQuals);
        }),
      );
    } else {
      return this.applicationService.getApplicationEnrolment(this.routeParams.applicationYear).pipe(
        switchMap((applicationEnrolments: ApplicationEnrolment[]) => {
          const enrolmentPriority = Number(this.routeParams.enrolmentPriority);
          // Load correct application enrolment by either priority order or display order
          const applicationEnrolment = CoursePlannerComponent.getRelevantApplicationEnrolment(
            applicationEnrolments,
            enrolmentPriority,
          );

          if (!applicationEnrolment) {
            return throwError('There is no enrolment with that priority.');
          }
          const enrolledQuals = applicationEnrolment.enrolledQualifications;
          this.enrolledQualifications = enrolledQuals;
          this.enrolmentInternalReference = applicationEnrolment.internalReference;

          return this.fullEnrolmentService.getEnrolledQualData(this.routeParams.applicationYear, enrolledQuals);
        }),
      );
    }
  }

  static getRelevantApplicationEnrolment(
    applicationEnrolments: ApplicationEnrolment[],
    enrolmentPriority: number,
  ): ApplicationEnrolment {
    if (CoursePlannerComponent.allApplicationEnrolmentsHaveAValidPriority(applicationEnrolments)) {
      return applicationEnrolments.sort((a, b) => a.priority - b.priority)[enrolmentPriority - 1];
    } else if (CoursePlannerComponent.allApplicationEnrolmentsHaveAValidDisplayOrder(applicationEnrolments)) {
      return applicationEnrolments.sort((a, b) => a.displayOrder - b.displayOrder)[enrolmentPriority - 1];
    } else {
      return applicationEnrolments.find((app) => app.priority === enrolmentPriority);
    }
  }

  static allApplicationEnrolmentsHaveAValidPriority(applicationEnrolments: ApplicationEnrolment[]) {
    return !applicationEnrolments.some((el) => !el.priority);
  }

  static allApplicationEnrolmentsHaveAValidDisplayOrder(applicationEnrolments: ApplicationEnrolment[]) {
    return !applicationEnrolments.some((el) => !el.displayOrder);
  }

  fetchViewDataSubscription() {
    return this.route.params
      .pipe(
        switchMap(() => this.fetchEnrolledQuals()),
        switchMap(([qualifications, courseOccurrences, teachingPeriods]) => {
          this.setupTeachingPeriods(teachingPeriods);

          const qualIndex = Number(this.routeParams.qualPriority);
          this.enrolledQualification = this.enrolledQualifications.find((eq) => eq.priority === qualIndex);
          this.qualification = qualifications.qualification.find((q) => q.code === this.enrolledQualification.code);
          this.enrolledQualification = this.enrolledQualification;
          if (!this.qualification || !this.enrolledQualification) {
            return throwError('There is no enroled qualification with that priority.');
          }

          const missingCourses = [];
          if (courseOccurrences && courseOccurrences.length) {
            // For each enroled qual, loop over each course Occurrence to build data for basket and search cards
            const currentCourses = this.enrolledQualifications.map((qual) => {
              return qual.enrolledCourses.map((ec) => {
                // Get course data from enrolledCourse occurrence code and checks whether course is in current enrolment
                const courseData = courseOccurrences.find((c) => c.courseOccurrenceCode === ec.code);
                const courseInEnrolment = !!this.enrolledQualification.enrolledCourses.find((c) => c.code === ec.code);
                // Handle mismatches between the enrolment course data and course occurrence data
                if (!courseData) {
                  missingCourses.push(ec.code);
                  return new CourseOccurrence({});
                }
                // Builds course data with attributes needed for course cards in basket and search results
                const extraCourseData = {
                  selectedCourse: true,
                  activeCourse: courseInEnrolment,
                  internalReference: ec.internalReference,
                  courseLevel: courseData.level / 100,
                  changeAction: null,
                };
                if (!!ec.changeAction) {
                  extraCourseData.changeAction = ec.changeAction;
                }
                const completeCourseData = { ...courseData, ...extraCourseData };
                completeCourseData.startDate = ec.startDate || courseData.startDate;
                completeCourseData.endDate = ec.endDate || courseData.endDate;
                completeCourseData.changeAction = ec.changeAction;
                completeCourseData.active = ec.active;
                return new CourseOccurrence(completeCourseData);
              });
            });

            if (missingCourses.length) {
              this.log.error(`Courses API did not return course information for ${missingCourses.join(', ')}`);
            }

            this.courseService.currentCourses = flatten(currentCourses);
          }
          // There may not be another qual in most cases, only concurrent degrees will have 2
          this.otherQual = this.enrolledQualifications.find(
            (qual) => qual.priority !== Number(this.routeParams.qualPriority),
          );

          return zip(
            this.courseService.getCourseGroups(this.enrolledQualification.code, this.routeParams.applicationYear),
            this.courseService.getSubjectGroups(),
          );
        }),
      )
      .subscribe(
        ([courseGroups, subjectGroups]) => {
          this.setupOptions(courseGroups, subjectGroups);
        },
        (err: UCError) => {
          this.log.warn('Could not resolve the data for the course planner', err);
          return this.router.navigate(['/']);
        },
      );
  }

  public setupOptions(courseGroups, subjectGroups) {
    this.contentSearchOptions = [
      {
        formControlName: 'qualGroup',
        options: [{ code: { code: '' }, name: this.strings.options.allCourses }],
      },
      {
        formControlName: 'subjectGroup',
        options: [{ code: { code: '' }, name: this.strings.options.allSubjects }],
      },
    ];
    this.contentSearchOptions[0].options.push(...courseGroups);
    this.contentSearchOptions[1].options.push(...subjectGroups);

    const isMicroCredentialProcess =
      this.processName === PROCESS_NAMES.MCED || this.processName === PROCESS_NAMES.MICRO_CREDENTIAL;
    if (this.qualification.code === 'CUP' || isMicroCredentialProcess) {
      // Remove the 'All Courses' option, and disable the subject group dropdown
      if (courseGroups.length) {
        this.contentSearchOptions[0].options.splice(0, 1);
      }
      this.contentSearchOptions[1].disabled = true;
    }
    // Always set the default after the options are provided
    this.defaultCurriculumGroup = get(this.qualification, 'defaultCurriculumGroup') || '';

    // Set a --- Please Select --- option if these are course groups but no defaultCurriculumGroup
    if (courseGroups.length && !this.defaultCurriculumGroup) {
      this.contentSearchOptions[0].options.unshift({ code: { code: '' }, name: this.strings.options.pleaseSelect });
    }
  }

  private setupTeachingPeriods(teachingPeriods: TeachingPeriod[]) {
    this.teachingPeriods = teachingPeriods;
    this.filterOrder.teachingPeriodCode = teachingPeriods.map((tp) => ({
      labelText: tp.description,
      value: tp.code,
    }));
  }

  showErrorModal(action: string, courseCode: string, message: string) {
    const modalBody = message || this.strings.modal.defaultErrorMessage;
    this.errorModal.message = this.strings.modal.body(courseCode, modalBody, action);
    this.errorModal.open();
  }

  addCourse(course: CourseOccurrence) {
    if (this.processName === PROCESS_NAMES.COE) {
      this.addChangeOfEnrolmentCourse(course);
      return;
    }

    const { applicationYear, qualPriority } = this.routeParams;
    const { courseOccurrenceCode, startDate, endDate, grossLength, coursePoints } = course;
    const enrolmentCourse = new EnrolledCourse({
      code: courseOccurrenceCode,
      startDate,
      endDate,
      duration: grossLength,
      points: coursePoints,
    });

    this.applicationService
      .addCourseToApplicationEnrolment(applicationYear, this.enrolmentInternalReference, qualPriority, enrolmentCourse)
      .subscribe(
        (enrolCourse) => {
          let { currentCourses } = this.courseService;
          course.internalReference = enrolCourse.internalReference;
          course.active = enrolCourse.active;
          if (currentCourses) {
            currentCourses.push(course);
          } else {
            currentCourses = [course];
          }
          course.selectedCourse = true;
          this.courseService.currentCourses = currentCourses;
          this.updateApplicationEnrolmentCourses(enrolCourse.internalReference, enrolCourse);
        },
        (err) => {
          const errMessage = get(err, 'data[0].detail');
          this.errorTriggered = true;
          this.showErrorModal('add', course.courseOccurrenceCode, errMessage);
        },
      );
  }

  addChangeOfEnrolmentCourse(course: CourseOccurrence) {
    const changeOfEnrolmentCourse = new ChangeOfEnrolmentCourse({
      code: course.courseOccurrenceCode,
      duration: course.grossLength,
      startDate: course.startDate,
      endDate: course.endDate,
      points: course.coursePoints,
    });
    this.changeOfEnrolmentService
      .addChangeOfEnrolmentCourse(this.enrolledQualification.internalReference, changeOfEnrolmentCourse)
      .subscribe(
        (coe) => {
          const lastChangedQual = coe.enrolledQualifications.find(
            (eq) => eq.internalReference === this.enrolledQualification.internalReference,
          );
          const lastChangedCourse = lastChangedQual.enrolledCourses.find(
            (ec) => ec.code === course.courseOccurrenceCode,
          );
          course.internalReference = lastChangedCourse.internalReference;
          course.active = lastChangedCourse.active;
          course.changeAction = CHANGE_ACTIONS.ADDED;
          let { currentCourses } = this.courseService;
          if (currentCourses) {
            currentCourses.push(course);
          } else {
            currentCourses = [course];
          }
          course.selectedCourse = true;
          this.courseService.currentCourses = currentCourses;
          this.enrolledQualification.enrolledCourses = lastChangedQual.enrolledCourses;
        },
        (err) => {
          const errMessage = get(err, 'data[0].detail');
          this.errorTriggered = true;
          this.showErrorModal('add', course.courseOccurrenceCode, errMessage);
        },
      );
  }

  removeCourse(course: CourseOccurrence) {
    const { applicationYear, qualPriority } = this.routeParams;
    if (this.processName === PROCESS_NAMES.COE) {
      this.removeChangeOfEnrolmentCourse(course);
      return;
    }

    this.applicationService
      .removeCourseFromApplicationEnrolment(
        applicationYear,
        this.enrolmentInternalReference,
        qualPriority,
        course.internalReference,
      )
      .subscribe(
        () => {
          const { currentCourses } = this.courseService;
          const newUniqueCourseOccurrences = currentCourses.filter(
            (c) => c.courseOccurrenceCode !== course.courseOccurrenceCode,
          );
          const duplicateCourses = currentCourses.filter((c) => c.courseOccurrenceCode === course.courseOccurrenceCode);
          const hasDuplicateCourseOccurrences = duplicateCourses.length > 0;

          // Necessary to remove courses that have duplicate occurrence codes but different start/end dates
          if (hasDuplicateCourseOccurrences) {
            const remainingDuplicates = duplicateCourses.filter(
              (c) => c.startDate !== course.startDate && c.endDate !== course.endDate,
            );
            this.courseService.currentCourses = newUniqueCourseOccurrences.concat(remainingDuplicates);
          } else {
            this.courseService.currentCourses = newUniqueCourseOccurrences;
          }
          this.updateApplicationEnrolmentCourses(course.internalReference);
        },
        (err) => {
          const errMessage = get(err, 'data[0].detail');
          this.errorTriggered = true;
          this.showErrorModal('remove', course.courseOccurrenceCode, errMessage);
        },
      );
  }

  removeChangeOfEnrolmentCourse(course: CourseOccurrence) {
    this.changeOfEnrolmentService
      .removeChangeOfEnrolmentCourse(this.enrolledQualification.internalReference, course.internalReference)
      .subscribe(
        (coe) => {
          const lastChangedQual = coe.enrolledQualifications.find(
            (eq) => eq.internalReference === this.enrolledQualification.internalReference,
          );
          const currentCourses = this.courseService.currentCourses.filter(
            (c) => c.internalReference !== course.internalReference,
          );

          course.changeAction = CHANGE_ACTIONS.DROPPED;
          course.activeCourse = true;

          currentCourses.push(course);
          this.courseService.currentCourses = currentCourses;
          this.enrolledQualification.enrolledCourses = lastChangedQual.enrolledCourses;
        },
        (err) => {
          const errMessage = get(err, 'data[0].detail');
          this.errorTriggered = true;
          this.showErrorModal('remove', course.internalReference, errMessage);
        },
      );
  }

  undoChange(course: CourseOccurrence) {
    const qualReference = this.enrolledQualification.internalReference;
    const selectedCourse = this.enrolledQualification.enrolledCourses.find(
      (ec) => ec.code === course.courseOccurrenceCode,
    );
    const courseReference = selectedCourse.internalReference;
    this.changeOfEnrolmentService
      .updateChangeOfEnrolmentCourse(qualReference, courseReference, CHANGE_ACTIONS.UNDO)
      .subscribe(() => {
        const changedCourse = new CourseOccurrence({ ...course, courseLevel: course.level / 100 });
        // remove/add course locally so we don't need to refresh all course data
        const currentCourses = this.courseService.currentCourses.filter(
          (c) => c.internalReference !== course.internalReference,
        );

        course.selectedCourse = course.changeAction === CHANGE_ACTIONS.DROPPED;

        if (course.changeAction === CHANGE_ACTIONS.DROPPED) {
          changedCourse.changeAction = CHANGE_ACTIONS.NONE;
          currentCourses.push(changedCourse);
        }
        this.courseService.currentCourses = currentCourses;
      });
  }

  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }

  updateApplicationEnrolmentCourses(internalReference, newCourse?) {
    const { qualPriority, enrolmentPriority } = this.routeParams;
    const enrolmentIndex = Number(enrolmentPriority) - 1;
    const qualIndex = Number(qualPriority) - 1;
    // if student adds a course, update the current applicationEnrolment (rather than fetching all new AE data)
    if (newCourse) {
      this.enrolledQualifications[qualIndex].enrolledCourses.push(newCourse);
    } else {
      // if student removes a course, remove this from the enrolledCourses list from the current applicationEnrolment.enrolledQualification
      const filteredCourses = this.enrolledQualifications[qualIndex].enrolledCourses.filter((c) => {
        return c.internalReference !== internalReference;
      });
      this.enrolledQualifications[qualIndex].enrolledCourses = filteredCourses;
    }
    const currentAEs: ApplicationEnrolment[] = this.applicationService.applicationEnrolments$.value;
    currentAEs[enrolmentIndex].enrolledQualifications = this.enrolledQualifications;
    this.applicationService.applicationEnrolments$.next(currentAEs);
  }

  updateSearchResultCount(count: number) {
    this.showGuidance = false;
    this.searchResultCount = count;
  }

  resetResults() {
    this.showGuidance = true;
    this.noCoursesFound = false;
    this.tooManyResults = false;
    this.courseService.clearSearch();

    if (!this.loading) {
      this.searchFilter.hideFilters();
      this.searchBar.clearSearchBar();
    }
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
    if (this.showFilters) {
      this.filterText = this.strings.hideFilters;
    } else {
      this.filterText = this.strings.filterResults;
    }
  }
}
