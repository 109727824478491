<div>
  <div
    class="item-label"
    *ngIf="enrolmentNumber >= 1 || isConcurrentQual()"
  >
    <div class="item-tab">{{ tabLabel() }}</div>
  </div>
  <div class="item-content-wrapper">
    <!-- TODO: wire up progress bar -->
    <div
      *ngIf="!minimalView"
      class="points-indicator__current-points-text"
    >
      {{ strings.totalPoints }} {{ enrolment.totalPoints }}
      <uc-task-hint
        [task]="task"
        path="/change_of_enrolment/0"
      ></uc-task-hint>
    </div>

    <div
      class="item-content-section"
      *ngFor="let qualification of enrolment.qualifications"
    >
      <h3 class="qualification-heading">
        {{ applicationYear }} {{ qualification.qualificationAnswer.title }} ({{
          qualification.qualificationAnswer.code
        }})
      </h3>

      <div class="enrolment__answers-wrapper">
        <uc-tag-list
          *ngIf="qualification.subjectAnswer"
          [subjectAnswer]="qualification.subjectAnswer"
          [cropLongText]="false"
          [subjectOptions]="qualification.qualificationAnswer?.subjectOptions"
        ></uc-tag-list>

        <div
          class="validation-messages"
          *ngIf="hasCourses(qualification)"
        >
          <h5 class="validation-messages__heading error-title">{{ strings.errorTitle }}</h5>
          <uc-message-banner type="warning">
            <span class="validation-messages_message">
              {{ strings.errorDescription }}
            </span>
          </uc-message-banner>
        </div>
      </div>

      <ng-container *ngIf="!minimalView">
        <uc-qualification-link
          *ngIf="qualification && qualification.qualificationAnswer"
          class="enrolment__qual-link"
          [qualCode]="qualification.qualificationAnswer.code"
        ></uc-qualification-link>
        <uc-button
          testSelector="course-selector"
          class="rounded secondary inline-icon-btn view-button"
          (click)="goToCourseSelector(qualification.priority)"
        >
          <uc-icon
            iconType="cap"
            iconWidth="22"
            iconHeight="22"
            iconFill="$colorWhite"
          ></uc-icon>
          <span [innerHTML]="courseSelectorButtonLabel"></span>
        </uc-button>

        <div class="selected-courses">{{ strings.selectedCourses(getNumberOfCoursesForQual(qualification)) }}</div>
      </ng-container>

      <div *ngFor="let bucket of qualification.courseBuckets">
        <ng-container *ngIf="bucket.points !== 0">
          <h4 class="section-heading">
            <span class="semester-title">{{ bucket.title }}</span>
            <span
              *ngIf="!minimalView"
              class="section-tally"
              >&nbsp;&bull;&nbsp;{{ bucket.points }} {{ strings.points }}</span
            >
          </h4>
          <ng-container *ngIf="!minimalView; else minimal">
            <ng-container *ngFor="let course of bucket.courses">
              <uc-course-view
                *ngIf="course.changeAction !== 'drop'"
                [course]="course"
                [showStates]="true"
              ></uc-course-view>
            </ng-container>
          </ng-container>
          <ng-template #minimal>
            <uc-course-minimal-view
              *ngFor="let course of bucket.courses"
              [course]="course"
              [showStates]="true"
            ></uc-course-minimal-view>
          </ng-template>
        </ng-container>
      </div>

      <div
        class="horizontal-divider"
        *ngIf="hasActiveAndInactiveCourses(qualification)"
      ></div>

      <div
        class="inactive__courses-wrapper"
        *ngIf="qualification?.inactiveCourses?.length > 0"
      >
        <h4 class="section-heading">
          <span class="semester-title inactive-courses">{{ strings.inactiveCourses }}</span>
        </h4>
        <uc-course-view
          *ngFor="let course of qualification.inactiveCourses"
          [course]="course"
          [showStates]="true"
        ></uc-course-view>
      </div>
    </div>

    <button
      [attr.data-cy]="'withdraw'"
      class="delete-btn"
      *ngIf="withdrawEnrolmentAction"
      (click)="openWithdrawModal()"
    >
      <uc-icon
        class="delete-btn-icon"
        iconType="trash"
        iconWidth="16"
        iconHeight="16"
        iconFill="$colorFireEngineRed"
      ></uc-icon>
      <span class="delete-btn-text">{{ strings.removeEnrolmentLabel }}</span>
    </button>
  </div>

  <ng-container *ngIf="!minimalView">
    <ng-container *ngIf="showValidationMessagesSection">
      <div
        class="item-content-wrapper"
        *ngIf="showValidationMessagesSection"
      >
        <h3 class="validation-messages__heading">
          <uc-icon
            iconType="caution"
            iconWidth="25"
            iconHeight="25"
            iconFill="$colorFireEngineRed"
          ></uc-icon>
          {{ strings.enrolmentCheckHeading }}
        </h3>

        <p
          class="validation-messages__help-text"
          [innerHTML]="strings.validationHelp"
        ></p>
      </div>

      <div
        class="item-content-wrapper validation-messages"
        *ngFor="let validationMessage of createEnrolmentValidationMessages(enrolment)"
      >
        <h5 class="validation-messages__heading">
          <uc-icon
            iconType="caution"
            iconWidth="25"
            iconHeight="25"
            iconFill="$colorAmber"
          ></uc-icon>
          {{ validationMessage.title }}
        </h5>
        <div
          class="validation-messages__guidance-text"
          *ngIf="validationMessage.guidanceText"
          [innerHTML]="validationMessage.guidanceText"
        ></div>
        <uc-message-banner
          type="warning"
          *ngFor="let message of validationMessage.messages"
        >
          <span class="validation-messages_message">
            {{ message.message }}
          </span>
        </uc-message-banner>
      </div>
    </ng-container>

    <div
      class="item-content-wrapper"
      *ngIf="showExemptionReasonsSection"
    >
      <h4 class="item-section-heading">
        <uc-icon
          iconType="doc"
          iconWidth="25"
          iconHeight="25"
          iconFill="$colorBlueRibbon"
        ></uc-icon>
        {{ strings.exemptionReasons }}
      </h4>
      <p>
        {{ strings.exemptionReasonsDesc }}
      </p>

      <uc-form-field-label labelText="{{ strings.exemptionReasons }}">
        <uc-subsection-minor-text>{{ strings.max500 }}</uc-subsection-minor-text>
        <uc-text-area
          testSelector="exemption-reason"
          [formControl]="exemptionReasonControl"
        ></uc-text-area>
      </uc-form-field-label>
    </div>
  </ng-container>
</div>

<uc-confirmation-modal
  #withdrawConfirmationModal
  [title]="strings.withdrawModal.header"
  [message]="strings.withdrawModal.body"
  [acceptText]="strings.withdrawModal.ok"
  (accepted)="withdrawApplicationEnrolment()"
  declineText="Cancel"
  declineType="diminished"
></uc-confirmation-modal>

<uc-confirmation-modal
  #withdrawErrorModal
  [title]="strings.withdrawErrorModal.header"
  [message]="strings.withdrawErrorModal.body"
  [acceptText]="strings.withdrawErrorModal.ok"
></uc-confirmation-modal>

<uc-confirmation-modal
  #embargoedInfoModal
  [title]="embargoedInfoModalStrings.header"
  [message]="embargoedInfoModalStrings.body"
  [acceptText]="embargoedInfoModalStrings.ok"
></uc-confirmation-modal>
